<template>
  <div class="opport">
    <div class="card" v-if="this.college != 'd9cdydbdh'">
<!--      <div class="title">{{ title }}</div>-->
      <img src="../assets/img/second/detailBg.jpg" class="opportbg" />
    </div>
    <div class="card" v-else>
<!--      <div class="title">{{ title }}</div>-->
      <img src="../assets/img/second/detailBg2.jpg" class="opportbg" />
    </div>
    <div class="tab" :class="college==='d9cdydbdh' ? 'tab-d9':''" v-show="getTabs && getTabs.length && !hide">
      <div v-if="college !=='d9cdydbdh'" style="position: absolute;height: 6.25vw;left: 0.10417vw;top: -3.125vw;background-color: #c13230;width: 10.41667vw;font-size: 1.5625vw;text-align: center;line-height: 6.25vw;color: #fff;">
        {{parentTitle}}
      </div>
      <div
        class="tab-item" 
        v-for="(item, index) in getTabs"
        :key="index"
        :class="columnId === item.columnId ? 'active' : ''"
        @click="getData(item)"
      >
        {{ item.title }}
        <div v-if="columnId === item.columnId" style="position: absolute;bottom: 0;width: 100%;display: flex;justify-content: center;">
          <div style="width: 2.08333vw;background-color: #c13230;height: 0.10417vw;"></div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="content-container" v-if="!isHideList">
      <div class="content">
        <div v-if="hide && college !=='d9cdydbdh'" style="text-align: left;font-size: 1.04167vw;padding: 0.52083vw;margin-top: 1.04167vw;color: #a73827;width: 100%;">{{ title }}</div>
        <div v-else-if="college === 'd9cdydbdh'" ></div>
        <div v-else style="margin-top: 3.64583vw;"></div>
        <div
            class="list-item"
            v-for="(item, index) in list"
            :key="index"
            @click="toDetail(item)"
        >

          <div class="right">
            <div class="title">
              <div class="ellipsis2" style="font-weight: normal;">{{ item.title }}</div>
            </div>
            <!--          <div class="des">-->
            <!--            <div class="ellipsis2">{{ item.description }}</div>-->
            <!--          </div>-->
            <!--          <div class="btn">了解详情</div>-->
<!--            <div class="see-detail">-->
<!--              了解详情 >-->
<!--            </div>-->
          </div>
          <div class="left">
            <div>
              <!--              <div class="day">-->
              <!--                {{ $moment(item.publishTime).format("DD") }}-->
              <!--              </div>-->
              <div class="year">
                {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="lmy-page" v-if="!isHideList">
      <el-pagination
        background
        @current-change="pageChange"
        :current-page="pageNum + 1"
        layout="prev, pager, next, total"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import {allCollegeInfo} from "../allCollegeInfo";

export default {
  name: "newsTrends",
  components: {},
  data() {
    return {
      columnId: "",
      title: "",
      hide: false,
      list: [],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      tab: "",
      tabs1: [
        // { title: "首页轮播图", columnId: this.$columnIdFile["轮播图"] },
        { title: "综合新闻", columnId: this.$columnIdFile["综合新闻"], parentTitle: '新闻资讯' },
        { title: "学院公告", columnId: this.$columnIdFile["学院公告"], parentTitle: '新闻资讯' },
        { title: "科研平台", columnId: this.$columnIdFile["科研平台"], parentTitle: '新闻资讯' },
        { title: "教育教学", columnId: this.$columnIdFile["教育教学"], parentTitle: '新闻资讯' },
        { title: "党建工作", columnId: this.$columnIdFile["党建工作"], parentTitle: '新闻资讯' },
        { title: "实习实践", columnId: this.$columnIdFile["实习实践"], parentTitle: '新闻资讯' },
      ],
      tabs2: [],
      tabs3: [
        { title: "中文系", columnId: this.$columnIdFile["中文系"],parentTitle: '师资队伍' },
        {
          title: "汉语国际教育系",
          columnId: this.$columnIdFile["汉语国际教育系"],parentTitle: '师资队伍'
        },
        { title: "新闻系", columnId: this.$columnIdFile["新闻系"],parentTitle: '师资队伍' },
        {
          title: "专家教授",
          columnId: this.$columnIdFile["专家教授"],parentTitle: '师资队伍'
        },
        {
          title: "客座教授",
          columnId: this.$columnIdFile["客座教授"],parentTitle: '师资队伍'
        },
      ],
      tabs4: [
        { title: "科研项目", columnId: this.$columnIdFile["科研项目"], parentTitle: '学术研究' },
        {
          title: "科研成果",
          columnId: this.$columnIdFile["科研成果"], parentTitle: '学术研究'
        },
        { title: "学术交流", columnId: this.$columnIdFile["学术交流"], parentTitle: '学术研究' },
      ],
      tabs5: [
        { title: "合作机构", columnId: this.$columnIdFile["合作机构"], parentTitle: '国际交流' },
        {
          title: "项目介绍",
          columnId: this.$columnIdFile["项目介绍"], parentTitle: '国际交流'
        },
        { title: "域外采风", columnId: this.$columnIdFile["域外采风"], parentTitle: '国际交流' },
      ],
      tabs6: [
        { title: "学生组织", columnId: this.$columnIdFile["学生组织"], parentTitle: '学生工作' },
        {
          title: "规章制度",
          columnId: this.$columnIdFile["规章制度"], parentTitle: '学生工作'
        },
        { title: "学生活动", columnId: this.$columnIdFile["学生活动"], parentTitle: '学生工作' },
      ],
      tabs7: [
        { title: "本科生培养", columnId: this.$columnIdFile["本科生培养"], parentTitle: '人才培养' },
        { title: "研究生培养", columnId: this.$columnIdFile["研究生培养"], parentTitle: '人才培养'},
      ],
      tabs8: [
        { title: "教师使用", columnId: this.$columnIdFile["教师使用"], parentTitle: '表格下载' },
        { title: "学生使用", columnId: this.$columnIdFile["学生使用"], parentTitle: '表格下载'},
      ],
      tabs9:[],
      tabs10:[],
      tabs11:[],
      tabs12:[],
      tabs13:[],
      tabs14:[],

      isHideList: false,
      navList: [],
      parentTitle: '',
      college: ''
    };
  },
  computed: {
    getTabs() {
      console.log('this.tab',this.tab,["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"].includes(this.tab),'this.tabs10:', this.tabs10 )
      if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"].includes(this.tab)) {
        return this["tabs" + this.tab];
      } else if (!this?.tab) {
        // 如果没有 则通过id去自查
        for (const key in this.$data) {
          if (key.startsWith("tabs") && Array.isArray(this[key])) {
            const foundItem = this[key].find(
              (item) => item.columnId === this.columnId
            );
            if (foundItem) {
              return this[key];
            }
          }
        }

        return null;
      } else {
        return [];
      }

    },
  },
  created() {
    this.college = this.$store.state.college;
    const tabs = this.college === 'whycbxy' ? []:this.$store.state.pageJson.content.tabs;
    Object.keys(tabs).forEach(key => {
      console.log(key);
      // this.$set(this, key, tabs[key]);
      this[key] = tabs[key];
    })
    console.log('this.getTabs==', this.getTabs, this.tabs10)
  },
  mounted() {
    this.hide = !!this.$route.query.hide;

    // this.$eventBus.$on("navList", (msg) => {
    //   this.navList = msg;
    //   console.log('this.navList', this.navList)
    // });
    // const str = localStorage.getItem('navList');
    // this.navList = JSON.parse(str);
    // console.log('this.navList', this.navList, this.getTabs)
    if (this.getTabs && this.getTabs.length) {
      this.parentTitle = this.getTabs[0].parentTitle;
    }
  },
  methods: {
    toDetail(item) {
      console.log('item', item)
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          this.$router.push({
            name: "newsDetail",
            params: {
              columnId: this.columnId,
              id: item.id,
            },
          });

          // this.$router.push({
          //   name: "newsDetailInner",
          //   params: {
          //     columnId: this.columnId,
          //     id: item.id,
          //   },
          //   query: this.$route.query
          // });


          // this.$router.push({
          //   path: `newsTrends/newsDetail/${this.columnId}/${item.id}`,
          //   params: {
          //     columnId: this.columnId,
          //     id: item.id,
          //   },
          //   query: this.$route.query
          // });
        }
      } else {
        this.$router.push({
          name: "newsDetail",
          params: {
            columnId: this.columnId,
            id: item.id,
          },
        });
      }
    },
    pageChange(val) {
      this.pageNum = val - 1;
      this.getList();
    },
    async getList() {
      const data = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        columnIds: this.columnId, //
      };
      const res = await this.API.basic.getHomeRotographList(data);

      let list = res.data.content || [];
      this.total = res.data.totalElements;
      this.list = list;
    },
    getData(item) {
      this.$router.push({name: 'newsTrends', query: { ...item, tab: this.tab } });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        console.log('columnId',this.$route.query.columnId)
        this.columnId = this.$route.query.columnId;
        this.title = this.$route.query?.title;
        this.tab = this.$route.query?.tab;
        this.hide = !!this.$route.query.hide;
        this.getList();
        const newsDetail = this.$route.fullPath.split('/')[2]
        this.isHideList = newsDetail && newsDetail === 'newsDetail';

        if (this.getTabs && this.getTabs.length) {
          this.parentTitle = this.getTabs[0].parentTitle;
        }
        console.log('this.getTabs', this.getTabs)
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.opport {
  .card {
    position: relative;
    .opportbg {
      width: 100%;
      height: 700px;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      font-size: 48px;
      color: #ffffff;
    }
  }
  .content {
    width: 1380px;
    margin: 0 auto;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: ;
    .title {
      font-weight: 500;
      font-size: 48px;
      color: #000000;
    }
    .list-item {
      width: 1380px;
      background: #fff;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding: 10px 200px;
      cursor: pointer;
      position: relative;
      height: 100px;
      //border-bottom: 1px solid rgb(221, 221, 221);
      .left {
        min-width: 120px;
        height: 80px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        //border: 2px solid #000000;
        //opacity: 0.6;
        //margin-right: 26px;
        .day {
          font-weight: 500;
          font-size: 30px;
          color: rgb(161, 41, 22);
          text-align: center;
        }
        .year {
          font-weight: 500;
          font-size: 18px;
          color: rgb(161, 41, 22);
          text-align: center;
        }
      }
      .right {
        display: flex;
        align-items: center;
        position: relative;
        width: 800px;
        .title {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
          //height: 30px;
          //margin-bottom: 20px;
        }
        .des {
          font-weight: 400;
          font-size: 20px;
          color: #666666;
          height: 56px;
          margin-bottom: 20px;
        }
        .btn {
          border-radius: 5px;
          border: 1px solid #c13230;
          padding: 10px 50px;
          width: fit-content;
          font-weight: 400;
          font-size: 20px;
          color: #c13230;
        }

      }
      .see-detail {
        position: absolute;
        left: 0px;
        bottom: 0px;
        color: #c13230;
        font-size: 14px;
      }
    }
    .list-item:hover {
      background: #c13230;
      .left {
        //border: 2px solid #fff;
        .day {
          color: #fff;
        }
        .year {
          color: #fff;
        }
      }
      .right {
        .title {
          color: #ffffff;
        }
        .des {
          color: #ffffff;
          opacity: 0.7;
        }
        .btn {
          border: 1px solid #fff;
          color: #fff;
        }

      }
      .see-detail {
        color: #fff;
      }
    }
  }
  .tab {
    width: 1380px;
    //padding: 20px 0;
    display: flex;
    justify-content: center;
    background: transparent;
    //position: absolute;
    //top: 500px;
    position: absolute;
    top: 700px;
    left: 267px;
    height: 60px;
    border-bottom: 1px solid rgb(238, 238, 238);
    .tab-item {
      margin: 0px 10px;
      //height: 120px;
      font-weight: 400;
      font-size: 20px;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      &:hover {
        //background-color: #c13230;
        color: #c13230;
      }
    }
    .active {
      //background: #c13230;
      color: #c13230;
    }
  }
}
.lmy-page {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
  //background: #fff;
  ::v-deep .el-pager li.active {
    background-color: #a12916 !important;
    color: #fff;
  }
  ::v-deep .el-pager li:hover {
    color: #a12916 !important;
  }
  ::v-deep .el-pagination button:hover {
    color: #a12916 !important;
  }
}
.content-container {
  //background: url("../assets/img/list-bg.png") no-repeat;
  background: url("../assets/img/浅色.png") no-repeat;
  //background: #EAE8D8;
  background-size: 100% 100%;
}
.tab-d9{
  width: 1380px !important;
    display: flex !important;
    justify-content: center !important;
    background: transparent !important;
    position: absolute !important;
    top: 572px !important;
    left: 0 !important;
    right: 0 !important;
    height: 128px !important;
    border-bottom: 1px solid rgb(238, 238, 238) !important;
    background: #fff !important;
    margin:0 auto;
}
</style>


